import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {hotjar} from 'react-hotjar'
import * as Sentry from '@sentry/browser'
import {BrowserRouter} from 'react-router-dom'
import './index.css'
import App from './App'

if (process.env.REACT_APP_STAGE === 'prod') {
  Sentry.init({dsn: 'https://69f2019553f84192909da12502cbaf94@sentry.io/5186293'})
  hotjar.initialize(1077130, 6)
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

