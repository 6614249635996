import React, {Component} from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import axios from 'axios'

import AccountReferrals from '../AccountReferrals/AccountReferrals'
import Login from '../Login/Login'
import Registration from '../Registration/Registration'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

import {PrivateRoute} from '../../hoc/PrivateRoute'
import {log} from 'react-fullstory'

class ReferralsApp extends Component {
  _isMounted = false

  state = {
    isAuthenticated: true,
    loading: false,
    refAccount: '',
    email: '',
    errorMessage: '',
    errors: [],
  }

  async componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      await this.checkAuth()
    }
  }

  checkAuth = async () => {
    this.setState({loading: true})

    try {
      const response = await axios.get(
        'https://eluapi.com/v1/users/me/',
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
        },
      )
      this.setState({
        isAuthenticated: true,
        loading: false,
        email: response.data.email,
      })
      if (this.state.isAuthenticated) {
        this.props.history.push(`/account/`)
      }

    } catch (error) {
      console.log(error)
      this.setState({isAuthenticated: false, loading: false})
      // localStorage.removeItem('token')
      Sentry.withScope(scope => {
        Sentry.captureException(error)
      })
    }
  }

  handleLogIn = async e => {
    e.preventDefault()

    const email = e.target[0].value

    try {
      const response = await axios.post(
        'https://eluapi.com/v1/users/login/',
        {
          email: email,
          password: e.target[1].value,
        },
      )
      this.setState({errorMessage: ''})
      localStorage.setItem('token', response.data.token)
      await this.checkAuth()

      if (this.state.isAuthenticated) {
        this.props.history.push(`/account/`)
      }
    } catch (error) {
      // console.log(error.response.data.non_field_errors[0])
      if (error) {
        this.setState({
          errorMessage: 'Kan niet inloggen met opgegeven gegevens.',
        })
      }
      Sentry.withScope(scope => {
        Sentry.captureException(error)
      })
      localStorage.removeItem('token')
    }
  }

  handleRegistration = async e => {
    e.preventDefault()
    const firstName = e.target[0].value
    const lastName = e.target[1].value
    const email = e.target[2].value
    const password1 = e.target[3].value
    const password2 = e.target[4].value

    try {
      const response = await axios.post(
        'https://eluapi.com/v1/users/singup/',
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password1: password1,
          password2: password2,
        },
      )
      this.setState({errorMessage: '', errors: []})
      localStorage.setItem('token', response.data.token)
      await this.checkAuth()

      if (this.state.isAuthenticated) {
        this.props.history.push(`/account/`)
      }
    } catch (error) {
      if (error) {
        this.setState({
          errors: error.response.data.errors,
        })
      }
      Sentry.withScope(scope => {
        Sentry.captureException(error)
      })
      localStorage.removeItem('token')
    }
  }

  handleLogout = () => {
    this.setState({isAuthenticated: false, email: '', refAccount: ''})
    localStorage.removeItem('token')
    this.props.history.push(`/login`)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <>
        <Navbar handleLogout={this.handleLogout} />
        {!this.state.loading ? (
          <Switch>
            <Route
              path="/login"
              render={() => (
                <Login
                  loading={this.state.loading}
                  errorMessage={this.state.errorMessage}
                  checkAuth={this.checkAuth}
                  refAccount={this.state.refAccount}
                  isAuthenticated={this.state.isAuthenticated}
                  getReferralAccount={this.getReferralAccount}
                  handleLogIn={this.handleLogIn}
                />
              )}
            />
            <Route
              path="/register"
              render={() => (
                <Registration
                  loading={this.state.loading}
                  errorMessage={this.state.errorMessage}
                  errors={this.state.errors}
                  checkAuth={this.checkAuth}
                  refAccount={this.state.refAccount}
                  isAuthenticated={this.state.isAuthenticated}
                  getReferralAccount={this.getReferralAccount}
                  handleRegistration={this.handleRegistration}
                />
              )}
            />
            <PrivateRoute
              path="/account/"
              data={this.state}
              component={AccountReferrals}
            />
            {<Route render={() => <Redirect to="/login" />} />}
          </Switch>
        ) : null}
        {!this.state.loading ? <Footer /> : null}
      </>
    )
  }
}

export default withRouter(ReferralsApp)
