import React from 'react'
import {Container} from 'react-bootstrap'

import LogoImg from '../../assets/images/logo_elugie_rgb.png'
import classes from './Navbar.module.css'

const Navbar = props => {
  const pathname = window.location.pathname.slice(0, 17)
  const checkPath = pathname.startsWith('/account/')
  return (
    <>
      <header className={classes.SiteHeader} role="banner">
        <div className={classes.BreadCrumb}/>
        <Container>
          <div className={classes.SiteHeaderRow}>
            <div className={classes.SiteHeaderLogoWrapper}>
              <a href="/">
                <img
                  alt="Elugie"
                  className={classes.Logo}
                  height={53}
                  width={173}
                  src={LogoImg}
                />
              </a>
            </div>
            {checkPath && (
              <div className={classes.SiteHeaderLogOut}>
                <button onClick={props.handleLogout}>Log out</button>
              </div>
            )}
          </div>
        </Container>
      </header>
    </>
  )
}

export default Navbar
