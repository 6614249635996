import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  width: auto;
  border: 0;
  background-color: #01a384;
  color: #ffffff;
  line-height: 40px;
  height: 45px;
  font-size: 20px;
  border-radius: 35px;
  cursor: pointer;
  padding: 0 40px;

  &:hover {
    background-color: #00b290;
    color: #ffffff;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: #dbdbdb;
  }

  @media only screen and (max-width: 990px) {
    width: 100%;
    font-size: 15px;
  }
`

const button = props => (
  <Button
    type='submit'
    name={props.name}
    onClick={props.clicked}
    disabled={props.disabled}
  >
    {props.children}
  </Button>
)

export default button
