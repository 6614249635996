import React, {Component} from 'react'
import axios from 'axios'
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import * as Sentry from '@sentry/browser'

import Button from '../../components/UI/Button/Button'

import InviteTable from '../../components/InviteTable/InviteTable'
import InviteForm from '../../components/InviteForm/InviteForm'

import {VideoContainer, Iframe} from '../../components/UI/Video/Video'
import {HeroTitle, SubTitle} from '../../components/UI/Title/Title'

class AccountReferrals extends Component {
  _isMounted = false

  state = {
    inviteContactModal: false,
    type: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    message: '',
    loadingInvitedList: false,
    invitedContacts: [],
    errors: [],
  }

  async componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      await this.getInvitedList()
    }
  }

  onChangeHandler = e => {
    const {name, value} = e.target
    this.setState({
      [name]: value,
    })
  }

  addContact = async e => {
    e.preventDefault()
    const data = {
      email: this.state.email,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phoneNumber,
      city: this.state.city,
      type_contact: this.state.type,
      message: this.state.message,
      aangebracht_door: this.props.email,
    }

    try {
      const response = await axios.post(
        'https://eluapi.com/v1/referral/contacts/',
        data,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
        },
      )
      this.handleClose('inviteContactModal')
      await this.getInvitedList()

      this.setState({
        type: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        city: '',
        errors: [],
      })
    } catch (error) {
      if (error) {
        this.setState({
          errors: error.response.data.errors,
        })
      }
      Sentry.withScope(scope => {
        scope.setExtra('data', data)
        Sentry.captureException(error)
      })
    }
  }

  handleShow = e => {
    const {name} = e.target

    this.setState(prevState => ({
      ...this.state,
      [name]: !prevState[name],
    }))
  }

  getInvitedList = async () => {
    this.setState({loadingInvitedList: true})

    try {
      const invitedLists = await axios.get(
        `https://eluapi.com/v1/referral/contacts/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
        },
      )
      this.setState({
        invitedContacts: invitedLists.data,
        loadingInvitedList: false,
      })
    } catch (error) {
      this.setState({loadingInvitedList: false})
      Sentry.withScope(scope => {
        Sentry.captureException(error)
      })
    }
  }

  handleClose = modalName => {
    this.setState({
      [modalName]: false,
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const style = {
      position: 'fixed',
      top: '50%',
      left: '50%',
    }

    return (
      <>
        {!this.props.loading ? (
          <Container>
            <InviteForm
              state={this.state}
              show={this.state.inviteContactModal}
              handleClose={this.handleClose}
              onChangeHandler={this.onChangeHandler}
              addContact={this.addContact}
              errors={this.state.errors}
            />
            <Row>
              <Col md={12}>
                <div
                  style={{
                    maxWidth: '730px',
                    margin: '4rem auto 60px',
                    textAlign: 'center',
                  }}
                >
                  <HeroTitle>
                    Bedankt voor uw interesse om ambassadeur te worden bij
                    Elugie
                  </HeroTitle>
                  <SubTitle>Bekijk de video en ontdek hoe deze tool werkt</SubTitle>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <VideoContainer>
                  <Iframe
                    title="Bedankt voor uw interesse om ambassadeur te worden bij
                    Elugie"
                    src="https://www.youtube.com/embed/skU6grVvm0Y"
                    width="560"
                    height="315"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  />
                </VideoContainer>
              </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
              <Col md={12}>
                <div
                  style={{
                    margin: '1rem auto 1rem',
                    textAlign: 'right',
                  }}
                >
                  <Button name="inviteContactModal" clicked={this.handleShow}>
                    <i className="fas fa-plus"/> VOEG EEN KENNIS TOE
                  </Button>
                </div>
                <InviteTable
                  state={this.state}
                  invitedContacts={this.state.invitedContacts}
                  handleShow={this.handleShow}
                  errors={this.state.errors}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <Spinner style={style} animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        )}
      </>
    )
  }
}

export default AccountReferrals
