import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {validateEmail} from '../../utils'

import Button from '../../components/UI/Button/Button'
import {HeroTitle} from '../../components/UI/Title/Title'
import styled from 'styled-components'


const FormControl = styled(Form.Control)`
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  border-radius: 5px;
  height: 3em !important;
  width: 100%;
`

class Registration extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password1: '',
    password2: '',
    errorMessage: '',
  }

  // input
  onChangeHandler = e => {
    const {name, value} = e.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    let disabledButton =
      this.state.firstName &&
      this.state.lastName &&
      validateEmail(this.state.email) &&
      this.state.password1 &&
      this.state.password2

    return (
      <>
        <Container>
          <Row>
            <Col md={12}>
              <div
                style={{
                  maxWidth: '730px',
                  margin: '4rem auto 30px',
                  textAlign: 'center',
                }}
              >
                <HeroTitle>Maak uw ambassadeursaccount aan</HeroTitle>
              </div>
            </Col>
          </Row>
          <Form
            style={{
              maxWidth: '670px',
              margin: '0 auto',
              position: 'relative',
            }}
            onSubmit={this.props.handleRegistration}
          >
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Voornaam*</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Voornaam"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChangeHandler}
                  isInvalid={!!this.props.errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {this.props.errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Achternaam*</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Achternaam"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChangeHandler}
                  isInvalid={!!this.props.errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {this.props.errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Email*</Form.Label>
              <FormControl
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.onChangeHandler}
                isInvalid={!!this.props.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {this.props.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Wachtwoord*</Form.Label>
              <FormControl
                type="password"
                placeholder="Wachtwoord"
                name="password1"
                value={this.state.password1}
                onChange={this.onChangeHandler}
                isInvalid={!!this.props.errors.password1 || !!this.props.errors.non_field_errors}
              />
              <Form.Control.Feedback type="invalid">
                {this.props.errors.password1 || this.props.errors.non_field_errors}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Bevestiging wachtwoord*</Form.Label>
              <FormControl
                type="password"
                placeholder="Bevestiging wachtwoord"
                name="password2"
                value={this.state.password2}
                onChange={this.onChangeHandler}
                isInvalid={!!this.props.errors.password2 || !!this.props.errors.non_field_errors}
              />
              <Form.Control.Feedback type="invalid">
                {this.props.errors.password2 || this.props.errors.non_field_errors}
              </Form.Control.Feedback>
            </Form.Group>

            <div style={{paddingTop: '2rem', textAlign: 'center'}}>
              <Button disabled={!disabledButton}>ACCOUNT AANMAKEN</Button>
            </div>
          </Form>
        </Container>
      </>
    )
  }
}

export default withRouter(Registration)
