import React, {Component} from 'react'
import {Spinner, Table} from 'react-bootstrap'
import LinkButton from '../UI/LinkButton/LinkButton'
import styled from 'styled-components'

const TableWrapper = styled(Table)`
  color: #2d3669 !important;
`

const TableHead = styled.thead`
   background-color: #fafafc;
  font-family: HelveticaNeueLTPro-Md, sans-serif !important;
  text-align: center;
`

const TableBody = styled.tbody`
   text-align: center;
`
const ReferenceStatus = styled.div`
  border-radius: 45px;
  background: #ffce34;
  color: #fff;
  text-align: center;
  max-width: 200px;
  padding: 3px 3px;
  margin: 0 auto;
`

class InviteTable extends Component {
  render() {
    return (
      <TableWrapper responsive bordered>
        <TableHead>
        <tr>
          <th>Naam</th>
          <th>Aangemaakt</th>
          <th>Bijgewerkt</th>
          <th>Status</th>
        </tr>
        </TableHead>
        <TableBody>
        {this.props.state.invitedContacts.length > 0 ? (
          this.props.state.invitedContacts.map((obj, index) => {
            const {referral_status, created_at, updated_at} = obj

            function convertDate(dateString) {
              let p = dateString.split(/\D/g)
              return [p[2], p[1], p[0]].join('-')
            }

            return (
              <tr key={index + obj.first_name}>
                <td>{obj.first_name + ' ' + obj.last_name.slice(0, 1)}.</td>
                <td>{convertDate(created_at.split('T')[0])}</td>
                <td>{convertDate(updated_at.split('T')[0])}</td>
                <td>
                  <ReferenceStatus
                    style={{
                      background:
                        referral_status.id === 1
                          ? '#ffce34'
                          : referral_status.id === 2
                          ? '#DD7230'
                          : referral_status.id === 3
                            ? '#8EA604'
                            : referral_status.id === 4
                              ? '#01a384'
                              : referral_status.id === 5
                                ? '#F25757'
                                : '#ffce34',
                    }}
                  >
                    {referral_status.status_name}
                  </ReferenceStatus>
                </td>
              </tr>
            )
          })
        ) : this.props.state.loadingInvitedList ? (
          <tr>
            <td style={{padding: '70px 0'}} colSpan="4">
              <Spinner
                style={{color: '#dee2e6'}}
                animation="border"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </td>
          </tr>
        ) : (
          <tr>
            <td style={{padding: '70px 0'}} colSpan="4">
              Er zijn nog geen kennissen toegevoegd
              <LinkButton
                name="inviteContactModal"
                clicked={this.props.handleShow}
              >
                Voeg uw eerste kennis toe
              </LinkButton>
            </td>
          </tr>
        )}
        </TableBody>
      </TableWrapper>
    )
  }
}

export default InviteTable
