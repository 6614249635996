import React from 'react'

import classes from './Footer.module.css'

const Footer = () => {
  const today = new Date()

  return (
    <div className={classes.LayoutFooterCopyright}>
      <span>©‎ Elugie {today.getFullYear()}</span>
    </div>
  )
}

export default Footer
