import styled from 'styled-components'

export const VideoContainer = styled.div`
      max-width: 730px;
      margin: 1rem auto 30px;
      text-align: center;
      position: relative;
`

export const Iframe = styled.iframe`
  @media only screen and (max-width: 990px) {
    width: 100%;
    height: 200px;
  }
`