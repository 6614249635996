import React from 'react'
import ReferralsApp from './containers/ReferralsApp/ReferralsApp'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div>
      <ReferralsApp />
    </div>
  )
}

export default App
