import React from 'react'
import {Modal, Form, Col, Alert, Row} from 'react-bootstrap'

import Button from '../UI/Button/Button'

import {validateEmail} from '../../utils'
import styled from 'styled-components'

const FormControl = styled(Form.Control)`
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  border-radius: 5px;
  height: 3em !important;
  width: 100%;
`

const InviteForm = props => {
  const disabledButton =
    props.state.type &&
    props.state.firstName &&
    props.state.lastName &&
    validateEmail(props.state.email) &&
    props.state.city &&
    props.state.phoneNumber
  return (
    <Modal
      onHide={() => props.handleClose('inviteContactModal')}
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          style={{
            maxWidth: '670px',
            margin: '0 auto',
            position: 'relative',
            padding: '50px 0',
          }}
        >
          <Form.Group>
            <Form.Label>Type*</Form.Label>
            <FormControl
              name="type"
              as="select"
              value={props.state.type}
              onChange={props.onChangeHandler}
              isInvalid={!!props.state.errors.type_contact}
            >
              <option value="" defaultValue disabled>
                Selecteer...
              </option>
              <option value="Particulier">Particulier</option>
              <option value="KMO">KMO</option>
            </FormControl>
            <Form.Control.Feedback type="invalid">
              {props.state.errors.type_contact}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Voornaam*</Form.Label>
              <FormControl
                type="text"
                placeholder="Voornaam"
                name="firstName"
                value={props.state.firstName}
                onChange={props.onChangeHandler}
                isInvalid={!!props.state.errors.first_name}
              />
              <Form.Control.Feedback type="invalid">
                {props.state.errors.first_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Achternaam*</Form.Label>
              <FormControl
                type="text"
                placeholder="Achternaam"
                name="lastName"
                value={props.state.lastName}
                onChange={props.onChangeHandler}
                isInvalid={!!props.state.errors.last_name}
              />
              <Form.Control.Feedback type="invalid">
                {props.state.errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Email*</Form.Label>
              <FormControl
                type="email"
                placeholder="Email"
                name="email"
                value={props.state.email}
                onChange={props.onChangeHandler}
                isInvalid={!!props.state.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {props.state.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Telefoonnummer*</Form.Label>
              <FormControl
                type="tel"
                placeholder="Telefoonnummer"
                name="phoneNumber"
                value={props.state.phoneNumber}
                onChange={props.onChangeHandler}
                isInvalid={!!props.state.errors.phone_number}
              />
              <Form.Control.Feedback type="invalid">
                {props.state.errors.phone_number}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Gemeente*</Form.Label>
            <FormControl
              type="text"
              placeholder="Gemeente"
              name="city"
              value={props.state.city}
              onChange={props.onChangeHandler}
              isInvalid={!!props.state.errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {props.state.errors.city}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group >
            <Form.Label>Extra informatie</Form.Label>
            <Form.Control
              name="message"
              value={props.state.message}
              onChange={props.onChangeHandler}
              placeholder="Uw relatie tot deze persoon, toonde hij/zij reeds interesse, andere info..."
              as="textarea"
              rows="3" />
          </Form.Group>
          {/*{props.errors.length !== 0 ? <Row>*/}
          {/*  <Col md={12}>*/}
          {/*    <Alert style={{textAlign: 'center'}} variant="warning">*/}
          {/*      {props.errors.map(error => {*/}
          {/*        return (<span key={error}>{error}</span>)*/}
          {/*      })}*/}
          {/*    </Alert>*/}
          {/*  </Col>*/}
          {/*</Row>: null}*/}
          <div style={{textAlign: 'center', marginTop: '30px'}}>
            <Button disabled={!disabledButton} clicked={props.addContact}>
              <i className="fas fa-plus" /> VOEG TOE
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default InviteForm
