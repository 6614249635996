import React from 'react'
import {Route, Redirect} from 'react-router-dom'

export const PrivateRoute = ({component: Component, data, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        data.isAuthenticated ? (
          <Component {...props} {...data} />
        ) : (
          <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )
      }
    />
  )
}

export default PrivateRoute
