import styled from 'styled-components'

export const HeroTitle = styled.h1`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  text-align: center;
  padding-bottom: 12px;
  max-width: 90%;
  margin: 0 auto;
  
   @media only screen and (max-width: 990px) {
    padding-bottom: 30px;
    max-width  : ${({widthMobile}) => widthMobile || '100%'};
  }
`
export const Title = styled.h2`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  font-size: 30px;
  margin: 0 auto;
  padding-bottom: ${({marginBottom}) => marginBottom || '15px'};
  padding-top: ${({marginTop}) => marginTop || '1px'};
  text-align: ${({align}) => align || 'left'};
  max-width: 70%;
  
`
export const SubTitle = styled.p`
  color: ${({color}) => color || '#212850'};
  font-size: ${({fontSize}) => fontSize || '18px'};
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
  padding-bottom: ${({marginBottom}) => marginBottom || '1px'};
  padding-top: ${({marginTop}) => marginTop || '1px'};
  max-width: ${({maxWidth}) => maxWidth || 'auto'};
  margin: 0 auto;
`

export const BoldTitle = styled(SubTitle)`
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: bold;
`
