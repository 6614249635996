import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap'

import Button from '../../components/UI/Button/Button'
import {HeroTitle, SubTitle} from '../../components/UI/Title/Title'
import {VideoContainer, Iframe} from '../../components/UI/Video/Video'
import {validateEmail} from '../../utils'
import styled from 'styled-components'


const LinkWrapper = styled.div`
    text-align: center;
    margin: 5px 0 20px 0;
`
const LinkButton = styled(Link)`
  display: block;
  border: 0;
  background-color: transparent;
  color: #01a384;
  font-size: 17px;
  cursor: pointer;
  margin: auto;

  &:hover {
    color: #00a385;
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    outline: 0 !important;
  }
`

const Input = styled.input.attrs(() => ({
  className: 'data-hj-whitelist',
}))`
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #dfe1e5;
  border-radius: 5px;
  display: block;
  height: 3.6em;
  width: 40%;
  margin: 7px auto;

  &:focus {
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    -moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
    box-shadow: 0 2px 11px 0 rgba(32, 33, 36, 0.1);
    outline: 0;
  }

  @media only screen and (max-width: 990px) {
    display: block;
    width: 100%;
    height: 3.6em;
    -webkit-appearance: none;
  }
`


class Login extends Component {
  state = {
    email: '',
    password: '',
    errorMessage: '',
  }

  // input
  onChangeHandler = e => {
    const {name, value} = e.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    let bannerAlert
    if (this.props.errorMessage.length > 0) {
      bannerAlert = (
        <Row>
          <Col md={12}>
            <Alert style={{textAlign: 'center'}} variant="warning">
              {this.props.errorMessage} <br />
              Let op: om deel te nemen moet u eerst een{' '}
              <Link to="/register">ambassadeursaccount aanmaken</Link>
            </Alert>
          </Col>
        </Row>
      )
    }
    const spinnerStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
    }

    const disabledButton =
      validateEmail(this.state.email) && this.state.password
    return (
      <>
        {!this.props.loading ? (
          <Container>
            <Row>
              <Col md={12}>
                <div
                  style={{
                    maxWidth: '730px',
                    margin: '4rem auto 30px',
                    textAlign: 'center',
                  }}
                >
                  <HeroTitle>Word Elugie ambassadeur</HeroTitle>
                  <SubTitle>
                    Ontvang 250 euro per centrale die bij ons in bestelling
                    komt.
                  </SubTitle>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <VideoContainer
                >
                  <Iframe
                    title="Word Elugie ambassadeur"
                    src="https://www.youtube.com/embed/qIksqE0Qt-Y"
                    width="560"
                    height="315"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  />
                </VideoContainer>
              </Col>
            </Row>
            <Row>
              <Col md={12}>{bannerAlert}</Col>
            </Row>
            <Row>
              <Col md={12}>
                <form onSubmit={this.props.handleLogIn}>
                  <Input
                    value={this.state.email}
                    onChange={this.onChangeHandler}
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                  <Input
                    value={this.state.password}
                    onChange={this.onChangeHandler}
                    name="password"
                    type="password"
                    placeholder="Wachtwoord"
                  />
                  <Row>
                    <Col md={12}>
                      <LinkWrapper>
                        <LinkButton to="/register">Nog geen account?</LinkButton>
                      </LinkWrapper>
                    </Col>
                  </Row>
                  <Col style={{paddingTop: '1rem', textAlign: 'center'}} md={12}>
                    <Button disabled={!disabledButton}>INLOGGEN</Button>
                  </Col>
                </form>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <Spinner style={spinnerStyle} animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        )}
      </>
    )
  }
}

export default withRouter(Login)
