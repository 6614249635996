import React from 'react'
import styled from 'styled-components'

const Link = styled.button`
  display: block;
  border: 0;
  background-color: transparent;
  color: #01a384;
  font-size: 17px;
  cursor: pointer;
  margin: auto;

  &:hover {
    color: #00a385;
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    outline: 0 !important;
  }
`

const linkButton = props => (
  <Link
    name={props.name}
    onClick={props.clicked}
  >
    {props.children}
  </Link>
)

export default linkButton
